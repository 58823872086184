import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "toolbar" }
const _hoisted_4 = ["element-loading-text"]
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 custom-loading-svg" }
const _hoisted_7 = { class: "fw-bolder text-muted bg-light" }
const _hoisted_8 = { class: "text-first min-w-100px" }
const _hoisted_9 = { class: "text-first min-w-100px" }
const _hoisted_10 = { class: "text-first min-w-100px" }
const _hoisted_11 = { class: "text-first min-w-100px" }
const _hoisted_12 = { class: "text-first min-w-100px" }
const _hoisted_13 = { class: "text-first min-w-50px" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "py-1" }
const _hoisted_16 = {
  key: 0,
  class: "d-flex justify-content-start flex-column"
}
const _hoisted_17 = { class: "text-dark fw-bolder text-hover-primary fs-6 text-nowrap" }
const _hoisted_18 = { class: "fw-light fs-8 text-gray-600" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-danger me-1 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Date = _resolveComponent("Date")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_CrudWebhook = _resolveComponent("CrudWebhook")!
  const _component_EditWebhook = _resolveComponent("EditWebhook")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/webhook/document",
            class: "btn btn-sm btn-dark"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Web Hook documentation")), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "card-body",
        "element-loading-text": _ctx.translate('loading')
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_7, [
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.translate("id")), 1),
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.translate("bot")), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.translate("url")), 1),
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.translate("applicable cases")), 1),
                _createElementVNode("th", _hoisted_12, _toDisplayString(_ctx.translate("date")), 1),
                _createElementVNode("th", _hoisted_13, _toDisplayString(_ctx.translate("the operation")), 1)
              ])
            ]),
            (_ctx.list)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                      _createElementVNode("td", null, _toDisplayString(item.id), 1),
                      _createElementVNode("td", _hoisted_15, [
                        (item.bot)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createElementVNode("span", _hoisted_17, _toDisplayString(item.bot && item.bot.name), 1),
                              _createElementVNode("span", _hoisted_18, _toDisplayString(item.bot.bot_id), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(item.url), 1),
                      _createElementVNode("td", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.setting.applicable_cases.split(
                      ','
                    ), (setting, i) => {
                          return (_openBlock(), _createElementBlock("span", {
                            key: i,
                            class: "badge badge-light m-1"
                          }, _toDisplayString(_ctx.translate(setting)), 1))
                        }), 128))
                      ]),
                      _createElementVNode("td", null, [
                        _createVNode(_component_Date, {
                          date: item.created_at
                        }, null, 8, ["date"])
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("button", {
                          "data-bs-toggle": "modal",
                          "data-bs-target": "#kt_modal_edit_webhook",
                          class: "btn btn-sm btn-outline btn-outline-dashed btn-active-light-info me-1 mb-1",
                          onClick: ($event: any) => (_ctx.selected = item)
                        }, _toDisplayString(_ctx.translate("edit")), 9, _hoisted_19),
                        _createVNode(_component_el_popconfirm, {
                          width: "220",
                          "confirm-button-text": _ctx.translate('yes'),
                          "cancel-button-text": _ctx.translate('cancel'),
                          "icon-color": "#626AEF",
                          title: 
                      _ctx.translate(
                        'You are deleting the selected Autoresponder are you sure'
                      )
                    ,
                          onConfirm: ($event: any) => (_ctx.deleteWebhook(item.id, item.bot.bot_id))
                        }, {
                          reference: _withCtx(() => [
                            _createElementVNode("button", _hoisted_20, _toDisplayString(_ctx.translate("delete")), 1)
                          ]),
                          _: 2
                        }, 1032, ["confirm-button-text", "cancel-button-text", "title", "onConfirm"])
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.list && _ctx.list.length === 0)
          ? (_openBlock(), _createBlock(_component_Empty, { key: 0 }))
          : _createCommentVNode("", true)
      ], 8, _hoisted_4)), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _createVNode(_component_CrudWebhook, { onUpdate: _ctx.getItems }, null, 8, ["onUpdate"]),
    _createVNode(_component_EditWebhook, {
      id: _ctx.selected?.id,
      url: _ctx.selected?.url,
      botId: _ctx.selected?.bot?.bot_id,
      applicable_cases: _ctx.selected?.setting.applicable_cases,
      onUpdate: _ctx.getItems
    }, null, 8, ["id", "url", "botId", "applicable_cases", "onUpdate"])
  ], 64))
}