
import { defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { AllBots } from "@/store/pinia/AllBots";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  emits: ["update"],
  props: {
    botId: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    applicable_cases: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();

    const options = ref([
      { name: "bulk_message", icon: "bi-chat" },
      { name: "secretary", icon: "bi-emoji-smile" },
      { name: "notification", icon: "bi-bell-fill" },
      { name: "bot_stopped", icon: "bi-sign-stop" },
    ]);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const editWebHook = ref<null | HTMLElement>(null);

    const targetData = ref({
      bot_id: "",
      url: "",
      setting: {
        applicable_cases: [] as any,
      },
    });

    const validationSchema = Yup.object().shape({
      bot_id: Yup.string().required().label("bot"),
      url: Yup.string().required().label("url"),
      applicable_cases: Yup.array().min(1).required().label("applicable cases"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.post(`bot/webhook/${props.id}/edit`, {
        bot_id: targetData.value.bot_id,
        url: targetData.value.url,
        setting: {
          applicable_cases: targetData.value.setting.applicable_cases.join(","),
        },
      })
        .then(({ data }) => {
          hideModal(editWebHook.value);
          emit("update");
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const AllBotsHolder = AllBots();
    const { botList } = storeToRefs(AllBotsHolder);

    watch(props, () => {
      targetData.value.bot_id = props.botId;
      targetData.value.url = props.url;
      let applicable_cases = props.applicable_cases;
      targetData.value.setting.applicable_cases = applicable_cases.split(",");
    });

    return {
      targetData,
      validationSchema,
      submit,
      submitButtonRef,
      editWebHook,
      translate,
      botList,
      options,
    };
  },
});
