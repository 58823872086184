
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { statusColor, translate } from "@/core/services/HelpersFun";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import CrudWebhook from "@/views/pages/other/webHook/CreateWebhook.vue";
import EditWebhook from "@/views/pages/other/webHook/EditWebhook.vue";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-table-my-ticket-list",
  components: {
    CrudWebhook,
    EditWebhook,
  },
  setup() {
    const list = ref();
    const loading = ref(true);
    const store = useStore();
    const selected = ref();
    const getItems = (data: any = {}) => {
      ApiService.query(`bot/webhook`, { params: data })
        .then(({ data }) => {
          list.value = data.data.webhooks;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const deleteWebhook = (id, bot_id) => {
      ApiService.post(`bot/webhook/${id}/delete`, {
        bot_id: bot_id,
      })
        .then(() => {
          getItems();
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    onMounted(() => {
      getItems();
      setCurrentPageBreadcrumbs(translate("web-hook"), []);
    });

    return {
      list,
      loading,
      getItems,
      statusColor,
      translate,
      selected,
      deleteWebhook,
    };
  },
});
